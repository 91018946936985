import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";

import makeStyles from "@material-ui/core/styles/makeStyles";
import ParagraphTypography from "./Typography/ParagraphTypography";
import HeaderTypography from "./Typography/HeaderTypography";

const useStyle = makeStyles((theme) => ({
	testimonialContainer: {
		width: "340px",
		height: "auto",
		minHeight: "520px",
		margin: "0 auto",
		marginTop: "6rem",
		padding: "46px 48px 40px 22px",
		objectFit: "contain",
		borderRadius: "8px",
		border: "solid 1px #a3c7c7",
		backgroundColor: "#fff",
		position: "relative",
		[theme.breakpoints.down("sm")]: {
			minHeight: "auto",
			marginTop: "4rem",
		},
	},
	imgCard: {
		display: "flex",
		position: "absolute",
		top: "-1.5rem",
		alignItems: "flex-end",
	},
	info: {
		marginLeft: "1rem",
		display: "flex",
		alignItems: "end",
		justifyContent: "center",
	},
	clientLogoBox: {
		position: "absolute",
		bottom: "1.5rem",
		width: "100%",
		display: "block",
		textAlign: "center",
		left: "0",
		[theme.breakpoints.down("sm")]: {
			position: "initial !important",
			margin: "0px",
			"& .gatsby-image-wrapper": {
				"& picture": {
					"& img": {
						position: "initial !important",
					},
				},
			},
		},
	},
	clientImg: {
		borderRadius: "4px",
		overflow: "auto",
	},
	clientName: {
		fontSize: "16px !important",
	},
	clientDesignation: {
		fontSize: "16px !important",
	},
}));

const TestimonialCard = ({ clientLogo, content, image, name, position }) => {
	const classes = useStyle();

	return (
		<Box className={classes.testimonialContainer}>
			<div className={classes.imgCard}>
				<div className={classes.clientImg}>
					{image}
				</div>
				<div className={classes.info}>
					<div>
						<HeaderTypography className={classes.clientName} component="h3" fontSize={16} fontWeight={600} mb={0} mt={0}>
							{name}
						</HeaderTypography>
						<HeaderTypography className={classes.clientDesignation} component="h4" fontSize={16} fontWeight={400} mb={0} mt={0}>
							{position}
						</HeaderTypography>
					</div>
				</div>
			</div>
			<Box mt={10}>
				<ParagraphTypography fontSize={16}>{content}</ParagraphTypography>
			</Box>
			<Box className={classes.clientLogoBox} mb={0} mt={4} textAlign="center">
				{clientLogo}
			</Box>
		</Box>
	);
};

TestimonialCard.propTypes = {};

export default TestimonialCard;
