import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import WhiteButton from "../components/v2/WhightButton";
import requestDemoBGImg from "../images/v2/request-demo-bg.svg";
import testimonialBannerBg from "../images/v2/testimonial-bg@2x.png";
import useWidth from "../hooks/useWidth";
import SEO from "../components/SEO";
import { Link } from "gatsby";
import TestimonialCard from "../components/v2/TestimonialCard";
import { StaticImage } from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({
	img: {
		maxWidth: "100%",
		height: "auto",
	},
	requestDemoRoot: {
		background: `url(${requestDemoBGImg})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
	},
	testimonialBanner: {
		background: `url(${testimonialBannerBg})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
		padding: "10rem 0",
		textAlign: "center",
		[theme.breakpoints.down("sm")]: {
			padding: "5rem 0",
		},
	},
}));

const testimonials = () => {
	const width = useWidth();
	const classes = useStyles();

	const clients = [
    {
      image: (
        <StaticImage
          alt="Pepper Cloud+Singapore Maritime Foundation"
          height="108"
          src="../images/v2/testimonial/wilson-tan-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <StaticImage
          alt="Singapore Maritime Foundation - Pepper Cloud customer"
          height="35"
          src="../images/v2/client-logos/singapore-maritime-foundation-pepper-cloud-customer.png"
        />
      ),
      name: "Wilson Tan",
      position: "Senior Manager",
      company: "Singapore Maritime Foundation, Singapore",
      contentSmall: `“Pepper Cloud is a good CRM tool to improve coordination among SMF departments and improve our database management.”`,
      content: (
        <React.Fragment>
          Pepper Cloud is a good CRM tool to improve coordination among SMF
          departments and improve our database management. The user-friendly
          interface, easy-to-use functions, and customisable features meant we
          could set up, integrate, and onboard the whole team on the cloud
          platform quickly. We appreciate the support and responsiveness of the
          Pepper Cloud team during the implementation process!
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud+TechData Corporation"
          height="108"
          src="../images/v2/testimonial/yoon-kam-fei-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <StaticImage
          alt="Tech Data - Pepper Cloud customer"
          height="35"
          src="../images/v2/client-logos/tech-data-pepper-cloud-customer.png"
        />
      ),
      name: "Yoon Kam Fei",
      position: "Managing Director",
      company: "Tec D Distribution Sdn Bhd, Malaysia",
      content: (
        <React.Fragment>
          Team collaboration, accurate sales forecasting, and effective resource
          management are the challenges every organisation encounters. But
          having an efficient CRM tool like Pepper Cloud enhances productivity
          while assisting in optimising the sales process and managing the sales
          pipeline. Pepper Cloud CRM tool is the right mix of flexibility,
          affordability, and ease of use.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + Mustard Seed System Corporation"
          height="108"
          src="../images/v2/testimonial/judith-adao-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <StaticImage
          alt="Mustard seed system corporation - Pepper Cloud customer"
          height="35"
          src="../images/v2/client-logos/mustard-seed-system-corporation-pepper-cloud-customer.png"
        />
      ),
      name: "Judith M. Adao",
      position: "General Manager",
      company: "Mustard Seed Systems Corporation, Philippines",
      content: (
        <React.Fragment>
          Pepper Cloud is a simple and easy to use CRM tool and it gives all the
          information needed to manage the sales leads. The CRM also helps us to
          run data-driven marketing campaigns. Pepper Cloud&apos;s customer
          service and local support are incredible and helped us at every stage
          of the implementation. This CRM is a must for every growing sales
          organisation.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + SiS Distribution (Thailand) PCL"
          height="108"
          src="../images/v2/testimonial/pantana-na-takuathung-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <StaticImage
          alt="SiS Distribution - Pepper cloud customer"
          height="35"
          src="../images/v2/client-logos/sis-distribution-pepper-cloud.png"
        />
      ),
      name: "Pantana Na Takuathung",
      position: "General Manager",
      company: "SiS Distribution (Thailand) Public Co., Ltd",
      content: (
        <React.Fragment>
          Pepper Cloud is an excellent all-in-one CRM tool to run multiple
          businesses across multiple domains. Through multiple sales pipelines,
          we track the business opportunities and the conversations between our
          team and our customers. Furthermore, this CRM software boosts the
          productivity of our team by helping us focus on core processes.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + Cuscapi Singapore Pte. Ltd."
          height="108"
          // src="../images/v2/testimonial/anthony-gerald-peppercloud-customer.png"
          src="../images/v2/testimonial/anthony-gerald-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <StaticImage
          alt="Cuscapi Singapore Pte. Ltd. - Pepper Cloud customer"
          height="35"
          src="../images/v2/client-logos/cuscapi-singapore-pte-ltd-pepper-cloud-customer.png"
        />
      ),
      name: "Anthony Gerald",
      position: "Group CEO",
      company: "Cuscapi Berhad, Malaysia",
      content: (
        <React.Fragment>
          The CRM has a strong sales pipeline management tool that helps us to
          manage our business operations in 6 countries. It has a robust user
          management system with clearly defined access levels for data security
          and protection, which we needed. What I personally like the most in
          Pepper Cloud CRM is its service dedication and personal touch. There
          is a face behind the product to whom we can reach out to whenever
          there is a problem.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + Tech Data Corporation Client"
          height="108"
          src="../images/v2/testimonial/sally-see-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <StaticImage
          alt="Tech Data - Pepper Cloud customer"
          height="35"
          src="../images/v2/client-logos/tech-data-pepper-cloud-customer.png"
        />
      ),
      name: "Sally See",
      position: "Marketing Manager",
      company: "Tec D Distribution Sdn Bhd, Malaysia",
      content: (
        <React.Fragment>
          Pepper Cloud CRM has made real-time data accessible to everyone in the
          company. Its association feature allows linking the business and
          activity records, helping us to track past and present customer
          interactions and allowing a higher level opportunity conversion.
          Additionally, Pepper Cloud CRM takes our feedback seriously. We feel
          that we do have a say in the product roadmap.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + Skypac Packaging Pte Ltd Client"
          height="108"
          src="../images/v2/testimonial/kelvin-peppercloud-customer.jpg"
          width="113"
        />
      ),
      clientLogo: (
        <StaticImage
          alt="Skypac Packaging - Pepper Cloud customer"
          height="35"
          src="../images/v2/client-logos/skypac-pepper-cloud-customer.png"
        />
      ),
      name: "Kelvin Yeo",
      position: "Director",
      company: "Skypac Packaging Pte Ltd",
      content: (
        <React.Fragment>
          Pepper Cloud is easy to use, flexible, and scalable CRM software,
          which is helping us to streamline our sales process, cut down
          repetitive tasks and increase our work efficiency. The support and
          training offered by the team are impeccable. Pepper Cloud perfectly
          fits the requirements of Singapore SMEs. The solution is also a part
          of ‘SME Go Digital’ which made us more confident in this solution.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + The supreme hr advisory"
          height="108"
          src="../images/v2/testimonial/joel-toh-peppercloud-customer.jpg"
          width="113"
        />
      ),
      clientLogo: (
        <StaticImage
          alt="The supreme hr advisory - Pepper Cloud customer"
          height="35"
          src="../images/v2/client-logos/the-supreme-hr-advisory-pepper-cloud-customer.png"
        />
      ),
      name: "Joel Toh",
      position: "Director",
      company: "The Supreme HR Advisory",
      content: (
        <React.Fragment>
          Pepper Cloud is a sales-focused CRM that is helping our business to
          grow. The user interface is easy and our sales process is now more
          organised. Apart from the highly productive CRM features, we are happy
          with their exceptional support and relentless assistance. Also, Pepper
          Cloud rolls out new features as per the feedback and customer
          requirements. We are happy with the product and the people behind the
          product.
        </React.Fragment>
      ),
    },
  ];

	return (
		<React.Fragment>
			<SEO
				canonical="/testimonials"
				description="Read Pepper Cloud CRM's customer experiences and reviews to learn why its a best sales CRM for small-medium businesses across multiple domains and counties."
				keywords="crm testimonials , customer experience ,Customer reviews"
				ogData={{
					"og:image": [
						"/meta/og/1X1/testimonials.png",
						"/meta/og/1024X512/testimonials.png",
						"/meta/og/1200X630/testimonials.png",
					],
				}}
				pathname="/testimonials"
				title="CRM Customer testimonials | Pepper Cloud"
			/>
			<Box className={classes.testimonialBanner}>
				<Container>
					<Box textAlign={{ sm: "center", xs: "center" }[width]}>
						<HeaderTypography component="h1" fontWeight={700} m={0} pb={4}>
							Our happy customers share their experiences
						</HeaderTypography>
						<ParagraphTypography m={0}>
							Read our customer experiences. Discover how businesses like yours found solutions with Pepper Cloud
							CRM software.
						</ParagraphTypography>
					</Box>
				</Container>
			</Box>

			<Container>
				<Box mb={6}>
					<Grid container spacing={0.5}>
						{clients.map((client) => (
							<Grid item key={client.name} lg={4} md={6} sm={6} xs={12}>
								<TestimonialCard {...client} />
							</Grid>
						))}
					</Grid>
				</Box>
			</Container>
			<Box className={classes.requestDemoRoot} py={12} textAlign="center">
				<Box alignItems="center" display="flex" justifyContent="center">
					<HeaderTypography color="common.white" fontSize={40} my={0} textAlign="center">
						Join our happy customers and
						<br /> increase your sales revenue
					</HeaderTypography>
				</Box>
				<Box mt={6} textAlign="center">
					<WhiteButton color="primary" component={Link} size="large" to="/contact" variant="contained">
						Book a free demo
					</WhiteButton>
				</Box>
			</Box>
		</React.Fragment>
	);
};

export default testimonials;
